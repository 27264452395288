import axios from 'axios';
import config from '../properties/Config';

const fetchPdfFromServer = async (documentId) => {
  try {
    const pdfUrl = `${config.BASE_URL}/document/${documentId}`;
    const response = await axios.get(pdfUrl, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/pdf',
      },
    });

    const data = response.data;

    if (data.byteLength === 0) {
      throw new Error('The PDF file is empty.');
    }

    const blob = new Blob([data], { type: 'application/pdf' });
    const pdfBlobUrl = URL.createObjectURL(blob);

    return pdfBlobUrl;
  } catch (error) {
    throw new Error(`Error fetching or processing PDF: ${error.message}`);
  }
};

const sendPngToServer = async (pngData, documentId, email) => {
  try {
    const formData = new FormData();
    formData.append('file', dataURItoBlob(pngData), 'drawing.png');
    formData.append('documentId', documentId);
    formData.append('email', email);

    const response = await axios.post(`${config.BASE_URL}/sign`, formData);

    if (response.status === 200) {
      window.location.reload();
      console.log("success");
    } else {
      throw new Error('Error sending PNG file to the server.');
    }
  } catch (error) {
    throw new Error(`Error occurred while sending the PNG file: ${error.message}`);
  }
};

const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

export { fetchPdfFromServer, sendPngToServer };
