import React from "react";
import '../css/Error.css';

const Error = () =>{
  return (
    <>
    <div className="error-page">
      <h1>404 Error Page</h1>
      <p>Sorry, This page doesn't exist.</p>
    </div>
    </>
  )
}

export default Error;
