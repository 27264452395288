import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, FormHelperText } from "@mui/material";
import AddSign from "../plusSign/views/AddSign";
import styles from "./DarkHome.module.css";
import FrameComponentStyles from "../components/FrameComponent.module.css";
import HandleFileChange from "../properties/HandleFileChange";
import uploadPdf from '../logo/upload.png';

const DarkHome = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileData, setSelectedFileData] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const handleFileChange = async (event) => {
    const { file, responseData, error } = await HandleFileChange(event, {});
    if (file && !error) {
      setSelectedFileData({ file, responseData });
      setUploadError(null);
    } else {
      console.error("Error uploading file:", error);
      setUploadError(error);
    }
  };
  return (
    <>
    
      {!selectedFileData && (
        
        <div className={styles.home}>
          <div className={styles.plusDocsChatParent}>
            <div className={styles.plusDocsChat}>Plus Sign</div>
            <img
              className={styles.frameChild}
              loading="lazy"
              alt=""
              src="/group-1.svg"
              
            />
          </div>


          <div className={styles.rectanglegroup}>
            <div className={styles.rectanglediv} />
            <div className={styles.ellipsecontainer} >
              <div className={styles.framechild1} />
              <img
                className={styles.vectoricon}
                loading="lazy"
                alt=""
                src="/vector.svg"
              />
            </div>
          </div>

          {uploadError && <p>Error uploading file: {uploadError}</p>}

          <section className={styles.homeInner}>
            <div className={styles.signSmartPlusAIParent}>
              <div className={styles.signSmartPlusAI}>
                <h1 className={styles.signSmartSign}>
                  Sign Smart With Plus Sign, Enhanced With AI
                </h1>
              </div>
              <div className={FrameComponentStyles.ellipseParent}>
                <div className={FrameComponentStyles.frameChild} />
                <div className={FrameComponentStyles.frameItem} />
                <div className={FrameComponentStyles.rectangleParent}>
                  <div className={FrameComponentStyles.frameInner} />
                  <img
                    className={FrameComponentStyles.downloadIcon}
                    loading="lazy"
                    alt=""
                    src={uploadPdf}
                  />
                  <b className={FrameComponentStyles.selectAPdf}>
                    Select a PDF file or drag here
                  </b>
                  <div className={FrameComponentStyles.selectAFile}>
                    <FormControl
                      className={FrameComponentStyles.fileContainer}
                      variant="standard"
                      sx={{
                        borderTopWidth: "1px",
                        borderRightWidth: "1px",
                        borderBottomWidth: "1px",
                        borderLeftWidth: "1px",
                        backgroundColor: "#151515",
                        borderRadius: "38.5px",
                        width: "99%",
                        height: "",
                        m: 0,
                        p: 0,
                        "& .MuiInputLabel-root": {
                          m: 0,
                          p: 0,
                          minHeight: "77px",
                          display: "inline-flex",
                        },
                        "& .MuiInputBase-root": {
                          m: 0,
                          p: 0,
                          minHeight: "77px",
                          justifyContent: "center",
                          display: "inline-flex",
                        },
                        "& .MuiInputBase-input": {
                          color: "#cbcbcb",
                          fontSize: 28,
                          fontWeight: "Black",
                          fontFamily: "Roboto",
                          margin: "1px",
                          textAlign: "left",
                          p: "38px",
                          justifyContent: "center",
                        },
                      }}
                    >
                      <InputLabel color="success" />
                      <input
                        type="file"
                        accept=".pdf"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                      <MenuItem
                        // style={{padding: "0.6rem 5.125rem" }}
                        onClick={() =>
                          document.querySelector("input[type='file']").click()
                        }
                      >
                        Select File
                      </MenuItem>
                      <FormHelperText />
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}

      {selectedFileData && (
        <AddSign
          selectedPdfData={selectedFileData.file}
          responseData={selectedFileData.responseData}
        />
      )}

      


    </>
  );
};

export default DarkHome;

