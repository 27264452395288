import Config from "../properties/Config";
import SentMail from "../emailpage/mail";

const sendDocumentForSign = async (myHeaders, raw) => {
  try {
    const response = await fetch(`${Config.BASE_URL}/send-document-for-sign`, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    });

    if (response.ok) {
      SentMail();
      const signedPdfUrl = await response.text();
      return signedPdfUrl;
    } else {
      console.error('Failed to send document for sign');
      return null;
    }
  } catch (error) {
    console.error('Error sending document for sign:', error);
    return null;
  }
};

export default sendDocumentForSign;
