import React, { useState } from "react";
const handleTextBoxClick = (
  e,
  isAddingTextBox,
  selectedPdfData,
  currentPage,
  scale,
  pdfViewerRef,
  textboxes,
  setTextboxes,
  setIsAddingTextBox,
  isPointInsideTextBox

) => {
  e.stopPropagation();

  if (isAddingTextBox && selectedPdfData) {
    const rect = pdfViewerRef.current.getBoundingClientRect();
    const x = (e.clientX - rect.left) / scale;
    const y = (e.clientY - rect.top) / scale;
    const pageWidth = pdfViewerRef.current.offsetWidth;
    const pageHeight = pdfViewerRef.current.offsetHeight;
    const isClickInsidePage = x >= 0 && x <= pageWidth && y >= 0 && y <= pageHeight;

    if (isClickInsidePage) {
      console.log('Clicked textbox coordinates:', { x, y });
      const isOccupied = textboxes.some(
        (textbox) => textbox.page === currentPage && isPointInsideTextBox(x, y, textbox)
      );

      if (!isOccupied) {
        const newTextBox = {
          id: Date.now(),
          x: x,
          y: y,
          content: '',
          saved: false,
          page: currentPage,
          locked: false,
          firstName: '',
          lastName: '',
          email: '',
        };

        setTextboxes((prevTextboxes) => [...prevTextboxes, newTextBox]);
        setIsAddingTextBox(false);
      }
    }
  }
};

export default handleTextBoxClick;