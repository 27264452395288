const handleTextBoxDrop = (coordinates, savedTextbox,setReceivedDataMap,setAddedTextBoxes,receivedDataMap,currentPage) => {
    console.log('Dropped Textbox Coordinates:', coordinates);
    console.log('Saved Textbox:', savedTextbox);

    const key = savedTextbox.id.toString(); 
    setReceivedDataMap((prevMap) => new Map(prevMap).set(key, coordinates));

    setAddedTextBoxes((prevAddedTextBoxes) => [
      ...prevAddedTextBoxes,
      {
        coordinates:
         {
          x : coordinates.x,
          y:coordinates.y,
          firstName: receivedDataMap.get(key)?.firstName || '',  
          lastName: receivedDataMap.get(key)?.lastName || '',    
          email: receivedDataMap.get(key)?.email || '',
          pageNo: currentPage,
        },
      },
    ]);
  };
  export default handleTextBoxDrop;
