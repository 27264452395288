import React, { useState } from 'react';
import "./ToggleButton.css";
import zoomout from '../../logo/zoom_out.png';
import zoomin from '../../logo/zoom_in.png';
import download from '../../logo/download.png';
import print from '../../logo/print.png';
import rotate from '../../logo/rotate.png';
import pageup from '../../logo/prev.png';
import down from '../../logo/next.png';

const ToggleOption = ({
  handleZoom,
  selectedPdfData,
  onPrint,
  handleRotate,
  onPageUp,
  onPageDown,
  numPages,
  currentPage,
  onDownload
}) => {
  const [scale, setScale] = useState(1.0);

  const handleDownloading = () => {
    if (selectedPdfData) {
      const blob = new Blob([selectedPdfData], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'PlusSign.pdf';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  const onPageJump = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= numPages) {
      if (pageNumber < currentPage) {
        onPageDown(pageNumber);
      } else if (pageNumber > currentPage) {
        onPageUp(pageNumber);
      }
    }
  };
  

  return (
    <div className='options'>
      <ul className='nav-option'>
        <li className='nav-button' onClick={() => handleZoom(-0.1)}>
          <button>
            <img className='slide-Bar' src={zoomout} alt="Zoom Out" />
          </button>
        </li>
        <li className='nav-button'>
          <button onClick={() => handleZoom(0.1)}>
            <img className='slide-Bar' src={zoomin} alt="Zoom In" />
          </button>
        </li>
        <li>
          <button className='nav-button' onClick={onDownload ? onDownload : handleDownloading}>
            <img className='slide-Bar' src={download} alt="Download" />
          </button>
        </li>
        <li>
          <button className='nav-button' onClick={onPrint}>
            <img className='slide-Bar' src={print} alt="Print" />
          </button>
        </li>
        <li>
          <button className='nav-button' onClick={handleRotate}>
            <img className='slide-Bar' src={rotate} alt="Rotate" />
          </button>
        </li>
        <li>
          <button className='nav-button' onClick={onPageDown} disabled={currentPage <= 1}>
            <img className='slide-Bar' src={pageup} alt="Previous Page" />
          </button>
        </li>
        <li>
          <input color='input-area' type="number" value={currentPage} onChange={(e) => onPageJump(parseInt(e.target.value))} min={1} max={numPages} style={{width:'58px',position:'absolute',left:'0px'}} />
        </li>
        <li>{`of ${numPages}`}</li>
        <li>
          <button className='nav-button' onClick={onPageUp} disabled={currentPage === numPages}>
            <img className='slide-Bar' src={down} alt="Next Page" />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ToggleOption;
