import React from "react";

const jsonRequest = (scale, pdfViewerRef, receivedDataMap) => {
  const textbox = receivedDataMap.values().next().value; 

  if (textbox) {
    const x = (textbox.x * scale + pdfViewerRef.current.scrollLeft) / scale;
    const y = (textbox.y * scale + pdfViewerRef.current.scrollTop) / scale;

    return {
      packageName: "Changed Text",
      firstName: textbox.firstName||"William",
      lastName: textbox.lastName||"A",
      email: textbox.email||"mehtashruti500@gmail.com",
      height: 54.14965986394557,
      left: x,
      top: y,
      width: 243.6734693877551,
    };
  }

  return null; 
};

export default jsonRequest;
