import React, { useState, useEffect, useRef } from 'react';
import styles from "./pages/DarkHome.module.css";
import { Document, Page, pdfjs } from 'react-pdf';
import DrawingCanvas from './DrawingCanvas';
import './css/SignDocsComponent.css';
import { fetchPdfFromServer, sendPngToServer } from './apiForPlussign/GetFilename';
import ToogleOtion from './plusSign/views/ToggleOption';
import SendIcon from '@mui/icons-material/Send';
import BeatLoader from "react-spinners/BeatLoader";
import HandleFileChange from './properties/HandleFileChange';
import Config from './properties/Config';


const SignDocsComponent = ({ documentId, email }) => {
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [scale, setScale] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [showDrawingCanvas, setShowDrawingCanvas] = useState(false);
  const [sentData, setSentData] = useState(null);
  const [newdocumentId, setNewdocumentId] = useState(null);
  const [message, setMessage] = useState('');
  const [response, setRespnose] = useState([])
  const [sentMessages, setSentMessages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const chatRef = useRef(null);


  const getResponse = async (message) => {
    setLoading(true);
    try {
        const requestData = {
            question: message,
            documentId: documentId
        };

        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData)
        };

        const res = await fetch(Config.Question_URL, requestOptions);
        const data = await res.json();
        setRespnose((prevResponse) => [...prevResponse, data]);
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
    }
};
  const handleMessage = (e) => {
    setMessage(e.target.value);
  }
  const handleData = (event) => {
    event.preventDefault();

    setMessage('');
    if (message.trim() !== '') {
      setSentMessages((prevMessages) => [...prevMessages, message])
      getResponse(message)
      setMessage("")
    }
    else {
      alert("Please ask any questions before sending...")
    }
  }
  const handleScrollBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }
  useEffect(() => {
    handleScrollBottom()
  }, [sentMessages, response])


  const handlePrint = () => {
    window.print();
  };

  const fetchPdf = async (documentId) => {

    setLoading(true);
    try {
      pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
      const pdfBlobUrl = await fetchPdfFromServer(documentId);
      setPdfBlobUrl(pdfBlobUrl);
      setLoading(false);

    } catch (error) {
      setError(error.message);
      console.error('Error fetching or processing PDF:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (documentId) {
      fetchPdf(documentId);
    }
  }, [documentId]);

  useEffect(() => {
    if (sentData) {
      console.log('Received Data:', sentData);
      sendPngToServer(sentData.drawnImage, documentId, email)
        .then((newdocumentId) => setNewdocumentId(newdocumentId))
        .catch((error) => console.error('Error sending PNG to the server:', error));
    }
    console.log("pdf:");
  }, [sentData, documentId, email]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const handlePageUp = () => {
    setPageNumber((prevPageNumber) => Math.max(1, prevPageNumber - 1));
    setCurrentPage(currentPage-1);
  };

  const handlePageDown = () => {
    setPageNumber((prevPageNumber) => Math.min(numPages, prevPageNumber + 1));
    setCurrentPage(currentPage+1);
  };


  const handleSendButtonClick = ({ drawnImage }) => {
    setTimeout(() => {
      console.log('Received drawn image:', drawnImage);
      setSentData({ drawnImage });
      setShowDrawingCanvas(false);
    }, 1000);
  };

  const handleRotateButtonClick = () => {
    setRotation((prevRotation) => (prevRotation + 90) % 360);
  };
  const handleDownloadButtonClick = () => {
    const link = document.createElement('a');
    link.href = pdfBlobUrl;
    link.download = `document_${pageNumber}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const handleCreateSignClick = () => {
    setShowDrawingCanvas(true);
  };

  const handleCloseDrawingCanvas = () => {
    setShowDrawingCanvas(false);
  };

  const handleZoom = (delta) => {
    setScale((prevScale) => Math.max(0.1, prevScale + delta));
  };

  return (
    <div className="sign-docs-container">
      {pdfBlobUrl  && !error && (
        <div className={styles.home}>
          <div className={styles.plusDocsChatParent}>
            <div className={styles.plusDocsChat}>Plus Sign</div>
            <img
              className={styles.frameChild}
              loading="lazy"
              alt=""
              src="/group-1.svg"
            />
          </div>
          <div className='flexiable'>
            <div className='header-sign' />

            <div className=" wrapper-class ">
              <span className='Signer'
                style={{ color: 'white', fontWeight: '900', marginLeft: '2rem', cursor: 'pointer' }}
                onClick={handleCreateSignClick}>Sign</span>
            </div>
            <ToogleOtion handleZoom={handleZoom} selectedPdfData={pdfBlobUrl} onPrint={handlePrint} handleRotate={handleRotateButtonClick} onPageUp={handlePageDown}
              onPageDown={handlePageUp} numPages={numPages} currentPage={currentPage} onDownload={handleDownloadButtonClick} />
            <div className="pdf-show">
              <Document file={pdfBlobUrl} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} rotate={rotation} />
              </Document>
            </div>
            {showDrawingCanvas && (
              <div className="popup-container">
                <div className="popup-content">
                  <button onClick={handleCloseDrawingCanvas} className="close-button">
                    Close
                  </button>
                  <DrawingCanvas onSend={handleSendButtonClick} onClick={handleCloseDrawingCanvas} />
                </div>
              </div>
            )}
         
              <div className='chat-container' style={{marginLeft : '6rem'}}>
                <div className='aiChat'>Chat With AI</div>
                <form onSubmit={handleData}>
                  <div className='chat-data' style={{ height: '60vh', overflowY: 'auto', paddingBottom: '0.8rem', marginTop: '0.7rem' }} ref={chatRef}>
                    {sentMessages.map((msg, i) => (
                      <div key={i}>
                        <span style={{ display: 'inline-block' }}>
                          <span style={{ color: '#00d322' }}>You&nbsp;</span>: {msg}
                        </span>
                        <div style={{ marginBottom: '0.5rem' }}>
                          <div style={{ display: 'inline-block' }}>

                            {loading && i === sentMessages.length - 1 ? (
                              <BeatLoader color='#36d7b7' />
                            ) : (
                              response[i] && (
                                <div style={{ display: 'inline-block' }}>
                                  <span><span style={{ color: '#00FF7F' }}>AI</span> &nbsp;&nbsp;&nbsp;: </span>{response[i].answer}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    ))}


                  </div>
                  <div className='sendAi' >
                    <input type='text' value={message} onChange={handleMessage} placeholder='Ask any question...' />
                    <div onClick={handleData} style={{ display: 'flex' }}>
                      <SendIcon style={{ color: '#00d322', fontSize: '40px', cursor: 'pointer' }} />

                    </div>

                  </div>
                </form>
              </div>


          </div>
        </div>
      )}
    </div>

  );
};

export default SignDocsComponent;
