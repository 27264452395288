import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import Draggable from 'react-draggable';
import jsonRequest from '../../onespan/api/properties/Request';
import sendDocumentForSign from '../../apiForPlussign/SentToSigntaure';
import createPayloadFromTextBoxes from '../../properties/Payload';
import SentMail from '../../emailpage/mail';
import payloadForOnespan from '../../onespan/api/properties/PaylaodForOnespan';
import handleDoubleClickSignHere from '../../properties/HandleDoubleClick';
import handleTextBoxClick from '../../properties/HandleTextboxClick';
import handleTextBoxDrop from '../../properties/HandleTextBoxDrop';
import TextBox from '../../TextBox';
import ToggleOption from './ToggleOption'
import SendIcon from '@mui/icons-material/Send';
import BeatLoader from "react-spinners/BeatLoader";
import styles from '../../pages/DarkHome.module.css'
import "./addsigner.css";
import Config from '../../properties/Config';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const AddSign = ({ selectedPdfData, responseData }) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [scale, setScale] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [pdfError, setPdfError] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [selectedOption, setSelectedOption] = useState('PlusSign');
    const [receivedDataMap, setReceivedDataMap] = useState(new Map());
    const [uploadedPdf, setUploadedPdf] = useState(null);
    const [isDocumentSent, setIsDocumentSent] = useState(false);
    const [rotation, setRotation] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [firstName, setFirstName] = useState('');
    const [textboxes, setTextboxes] = useState([]);
    const [isAddingTextBox, setIsAddingTextBox] = useState(false);
    const [showSquareBox, setShowSquareBox] = useState(false);
    const [squareBoxCoordinates, setSquareBoxCoordinates] = useState({ x: 0, y: 0 });
    const [activeSignId, setActiveSignId] = useState(null);
    const [addedTextBoxes, setAddedTextBoxes] = useState([]);
    const [message, setMessage] = useState('');
    const [response, setRespnose] = useState([])
    const [sentMessages, setSentMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const chatRef = useRef(null);



    const getResponse = async (message) => {
    setLoading(true);
    try {
        const requestData = {
            question: message,
            documentId: responseData.documentId
        };

        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData)
        };

        const res = await fetch(Config.Question_URL, requestOptions);
        const data = await res.json();
        setRespnose((prevResponse) => [...prevResponse, data]);
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
    }
};

    const handleMessage = (e) => {
        setMessage(e.target.value);
    }
    const handleData = (event) => {
        event.preventDefault();
        
        setMessage('');
        if (message.trim() !== '') {
            setSentMessages((prevMessages) => [...prevMessages, message])
            getResponse(message, responseData.sourceId)
            setMessage("")
        }
        else {
            alert("Please ask any questions before sending...")
        }
        // }
    }

    const handleScrollBottom = () => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }
    useEffect(() => {
        handleScrollBottom()
    }, [sentMessages, response])

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = selectedPdfData;
        link.download = 'document.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        console.log("pdf", selectedPdfData);
    };
    const nodeRef = React.useRef(null);
    const fileInputRef = useRef(null);
    const pdfViewerRef = useRef(null);

    const handleTextBoxChange = (id, changes) => {
        const updatedTextboxes = textboxes.map((textbox) =>
            textbox.id === id ? { ...textbox, ...changes } : textbox
        );
        setTextboxes(updatedTextboxes);
    };
    const handleZoom = (delta) => {
        setScale((prevScale) => Math.max(0.1, prevScale + delta));
    };

    const handleRotate = () => {
        setRotation((prevRotation) => (prevRotation + 90) % 360);
    };
    const handleSaveTextBox = (id) => {
        const updatedTextboxes = textboxes.map((textbox) => {
            if (textbox.id === id) {
                return {
                    ...textbox,
                    saved: true,
                    x: textbox.x + 120,
                    y: textbox.y + 200,
                };
            }
            return textbox;
        });
        setTextboxes(updatedTextboxes);
    };
    const handleAddTextBox = (e) => {
        e.stopPropagation();
        setIsAddingTextBox(!isAddingTextBox);
    };

    const handleCancelSquareBox = () => {
        setTextboxes((prevTextboxes) =>
            prevTextboxes.filter((textbox) => textbox.id !== activeSignId)
        );
        setShowSquareBox(false);
    };
    const isPointInsideTextBox = (x, y, textbox) => {
        const boxLeft = textbox.x * scale;
        const boxRight = (textbox.x + textbox.width) * scale;
        const boxTop = textbox.y * scale;
        const boxBottom = (textbox.y + textbox.height) * scale;

        return x >= boxLeft && x <= boxRight && y >= boxTop && y <= boxBottom;
    };
    const handleSaveSquareBox = () => {
        handleSaveSquareBox(activeSignId, squareBoxCoordinates, scale, currentPage, setTextboxes, setShowSquareBox);
    };

    const handlePageChange = (event) => {
        const requestedPage = parseInt(event.target.value, 10);
        if (!isNaN(requestedPage) && requestedPage >= 1 && requestedPage <= totalPages) {
            setCurrentPage(requestedPage);
        }
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setTotalPages(numPages);
        setPdfError(false);
    };
    const handleSubmitSignPopup = async () => {
        console.log("option is..", selectedOption);
        if (selectedOption == 'Onespan') {
            const request = jsonRequest(scale, pdfViewerRef, receivedDataMap);
            const formData = new FormData();
            formData.append(
                'file',
                new Blob([await fetch(selectedPdfData).then((response) => response.blob())], { type: 'application/pdf' })
            );
            formData.append('payload', JSON.stringify(payloadForOnespan));
            formData.append('request', JSON.stringify(request));

            const signedPdfUrl = await sendDocumentForSign(formData);
            if (signedPdfUrl) {
                setUploadedPdf(signedPdfUrl);
                setNumPages(null);
                setTotalPages(0);
                setPdfError(false);
            } else {
                console.error('Failed to send document for sign');
            }
        }
        else {
            const payload = createPayloadFromTextBoxes(responseData, selectedOption, scale, pdfViewerRef, receivedDataMap);
            if (payload.coordinates.length > 0) {
                const myHeaders = new Headers();
                const raw = JSON.stringify(payload);

                myHeaders.append('Content-Type', 'application/json');

                const signedPdfUrl = await sendDocumentForSign(myHeaders, raw);

                if (signedPdfUrl) {
                    setIsDocumentSent(true);
                    console.log(selectedPdfData);
                    setIsDocumentSent(true);
                    setUploadedPdf(signedPdfUrl);
                    setNumPages(null);
                    setIsLoading(false);
                    setTotalPages(0);
                    setPdfError(false);
                }
            } else {
                setIsLoading(false);
                console.error('Failed to send document for sign');
            }
        }

    };
    const handlePrint = () => {
        window.print();
    };

    const handlePageUp = () => {
        if (currentPage < numPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageDown = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    


    const onDocumentLoadError = (error) => {
        console.error('Error loading PDF:', error);
        setPdfError(true);
    };

    useEffect(() => {
        if (selectedPdfData) {
            const pdfBlob = new Blob([selectedPdfData], { type: "application/pdf" });
            setPdfUrl(URL.createObjectURL(pdfBlob));
        }
    }, [selectedPdfData]);

    return (

        <div className='container'>
            {isLoading ? (
                <div className="loader">Loading...</div>
            ) :
                <>
                    {isDocumentSent ? (
                        <SentMail />
                    ) :

                        (
                            <div className="mysign">
                                <div className='content'>
                                    <div className={styles.plusDocsChatParent}>
                                        <div className={styles.plusDocsChat} style={{ color: 'white' }}>Plus Sign</div>
                                        <img
                                            className={styles.frameChild}
                                            loading="lazy"
                                            alt=""
                                            src="/group-1.svg"
                                            style={{width : '12%'}}
                                        />
                                    </div>
                                
                                </div>
                                <div className='wrapper-container'>
                                   
                                    <div className="wrapper-name">
                                        <span className='Signer' 
                                        style={{ color: 'white', fontWeight: '900', marginLeft: '2rem', cursor: 'pointer' }}
                                         onClick={handleAddTextBox}>Add Signer</span>
                                    </div>

                                    <div className='send-to-sign-btn'>
                                        <div className='flex' style={{ cursor: 'pointer' }}>
                                            <div className="send-sign" onClick={handleSubmitSignPopup}>
                                                Send to Sign
                                            </div>
                                            <img className='sendMsg3' src="/sendmessage-3.svg" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className='pdf-with-talk'>
                                    <div style={{ width: '5%' }}>
                                        <ToggleOption handleZoom={handleZoom} selectedPdfData={selectedPdfData} onPrint={handlePrint} handleRotate={handleRotate} onPageUp={handlePageUp} onPageDown={handlePageDown} numPages={numPages} currentPage={currentPage}/>

                                    </div>
                                    <div className="pdf-container">
                                        <div className="pdf-view"
                                            ref={pdfViewerRef}
                                            onClick={(e) =>
                                                handleTextBoxClick(e, isAddingTextBox, selectedPdfData, currentPage, scale, pdfViewerRef, textboxes, setTextboxes,
                                                    setIsAddingTextBox, isPointInsideTextBox)}

                                            onDoubleClick={(e) => handleDoubleClickSignHere(e, isAddingTextBox, activeSignId, setTextboxes, setShowSquareBox, setActiveSignId, pdfViewerRef, scale, setSquareBoxCoordinates)}
                                            style={{
                                                cursor: isAddingTextBox ? 'crosshair' : !firstName ? 'auto' : 'crosshair', width: '796px'
                                            }}
                                        >
                                            {selectedPdfData && !pdfError && (
                                                <>
                                                    <Document file={selectedPdfData} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError} >
                                                        <Page pageNumber={currentPage} scale={scale} rotate={rotation} />
                                                    </Document>
                                                    {textboxes.map((textbox) => {
                                                        if (textbox.page === currentPage) {
                                                            const x1 = parseFloat(textbox.x) + 120;
                                                            const y1 = parseFloat(textbox.y) + 200;
                                                            console.log("X :", x1, "Y:", y1);
                                                            return (
                                                                <Draggable
                                                                    nodeRef={nodeRef}
                                                                    key={textbox.id}
                                                                    bounds="parent"
                                                                    onStop={(e, data) => {
                                                                        const updatedTextboxes = textboxes.map((tb) =>
                                                                            tb.id === textbox.id
                                                                                ? {
                                                                                    ...textbox,
                                                                                    x: (data.x / scale).toFixed(1),
                                                                                    y: (data.y / scale).toFixed(1),
                                                                                }
                                                                                : tb
                                                                        );
                                                                        setTextboxes(updatedTextboxes);
                                                                    }}
                                                                >
                                                                    <div
                                                                        className={`textbox ${textbox.saved ? 'saved' : ''}`}
                                                                        style={{ left: '122px', top: '200px', height: '63px', padding: '0', margin: '0', width: '224px' }}
                                                                        ref={nodeRef} onClick={(e) => e.stopPropagation()}>
                                                                        <TextBox id={textbox.id} firstName={textbox.firstName} x={x1}
                                                                            y={y1} lastName={textbox.lastName} email={textbox.email} pageNumber={currentPage} onChange={handleTextBoxChange} onSave={() => handleSaveTextBox(textbox.id)} onTextBoxDrop={(coordinates, savedTextbox) => handleTextBoxDrop(coordinates, savedTextbox, setReceivedDataMap, setAddedTextBoxes, receivedDataMap, currentPage)} />
                                                                    </div>
                                                                </Draggable>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                    {showSquareBox && (
                                                        <div
                                                            className="square-box"
                                                            style={{ left: squareBoxCoordinates.x * scale + 'px', top: squareBoxCoordinates.y * scale + 'px', position: 'absolute', zIndex: 999, display: 'flex', flexDirection: 'column', padding: '10px', alignItems: 'flex-start' }}>
                                                            <TextBox onSave={handleSaveSquareBox} onCancel={handleCancelSquareBox} />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            {isUploading && <p>Uploading PDF...</p>}
                                            {pdfError && <p>Error loading PDF. Please try again.</p>}
                                        </div>
                                    </div>



                                    <div className='chat-container'>
                                        <div className='aiChat'>Chat With AI</div>
                                        <form onSubmit={handleData}>
                                            <div className='chat-data' style={{ height: '60vh', overflowY: 'auto', paddingBottom: '0.8rem', marginTop: '0.7rem' }} ref={chatRef}>
                                                {sentMessages.map((msg, i) => (
                                                    <div key={i}>
                                                        <span style={{ display: 'inline-block' }}>
                                                            <span style={{ color: '#00d322' }}>You&nbsp;</span>: {msg}
                                                        </span>
                                                        <div style={{ marginBottom: '0.5rem' }}>
                                                            <div style={{ display: 'inline-block' }}>

                                                                {loading && i === sentMessages.length - 1 ? (
                                                                    <BeatLoader color='#36d7b7' />
                                                                ) : (
                                                                    response[i] && (
                                                                        <div style={{ display: 'inline-block' }}>
                                                                            <span><span style={{ color: '#00FF7F' }}>AI</span> &nbsp;&nbsp;&nbsp;: </span>{response[i].answer}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}


                                            </div>
                                            <div className='sendAi' >
                                                <input type='text' value={message} onChange={handleMessage} placeholder='Ask any question...' />
                                                <div onClick={handleData} style={{ display: 'flex' }}>
                                                    <SendIcon style={{ color: '#00d322', fontSize: '40px', cursor: 'pointer' }} />
                                                  
                                                </div>

                                            </div>
                                        </form>
                                    </div>


                                </div>


                            </div>
                        )}
                </>
            }
        </div >
    );
};

export default AddSign;