import React, { useState, useRef, useEffect } from "react";
import '../src/css/TextBox.css';

const TextBox = ({ onTextBoxDrop, pageNumber, x, y, ...otherProps }) => {
  const [textbox, setTextbox] = useState(null);
  const [savedTextboxes, setSavedTextboxes] = useState([]);
  const [showTextBox, setShowTextBox] = useState(true);
  const [errors, setErrors] = useState({});
  const [lockSignHere, setLockSignHere] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const signHereButtonRef = useRef(null);

  useEffect(() => {
    if (savedTextboxes.length > 0) {
      setLockSignHere(true);
      setShowTextBox(false);
    }
  }, [savedTextboxes]);

  const handleAddTextBox = () => {
    console.log("clicked sign here ");
    if (!textbox && !lockSignHere) {
      setTextbox({
        id: Date.now(),
        firstName: "",
        lastName: "",
        email: "",
      });
      setErrors({});
      setCurrentPage(pageNumber);
    } else {
      console.log("Only one textbox is allowed or Sign Here is locked!");
    }
  };

  const handleChange = (field, value) => {
    if (!lockSignHere) {
      setTextbox((prevTextbox) => ({ ...prevTextbox, [field]: value }));
      setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
    } else {
      console.log("Sign Here is locked!");
    }
  };

  const handleTextBoxDrop = (event, data, savedTextbox) => {
    const coordinates = {
      x: x,
      y: y,
      cName: "signature",
      pageNo: pageNumber,
      firstName: savedTextbox.firstName,
      lastName: savedTextbox.lastName,
      email: savedTextbox.email,
    };
    onTextBoxDrop(coordinates, savedTextbox, pageNumber);
  };

  const validateInputs = () => {
    const newErrors = {};

    if (!textbox.firstName.trim()) {
      newErrors.firstName = "First name is required";
    } else if (!textbox.firstName.match(/^[a-zA-Z]*$/)) {
      newErrors.firstName = "First name should only contain alphabets";
    }

    if (!textbox.lastName.trim()) {
      newErrors.lastName = "Last name is required";
    } else if (!textbox.lastName.match(/^[a-zA-Z]*$/)) {
      newErrors.lastName = "Last name should only contain alphabets";
    }

    if (!textbox.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!textbox.email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
      newErrors.email = "Invalid email address";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleDeleteTextBox = () => {
    if (!lockSignHere) {
      setTextbox(null);
      // setShowTextBox(false);
      setErrors({});
    } else {
      console.log("Sign Here is locked!");
    }
  };

  const handleSaveTextBox = () => {
    if (!lockSignHere && validateInputs()) {
      const savedTextbox = {
        ...textbox,
        pageNo: pageNumber,
        x: x,
        y: y,
      };
      setSavedTextboxes((prevSavedTextboxes) => [...prevSavedTextboxes, savedTextbox]);
      setTextbox(null);
      onTextBoxDrop(
        {
          x: x,
          y: y,
          cName: "signature",
          pageNo: pageNumber,
          firstName: savedTextbox.firstName,
          lastName: savedTextbox.lastName,
          email: savedTextbox.email,
        },
        savedTextbox,
        pageNumber
      );
    } else {
      console.log("Sign Here is locked or validation failed!");
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    setLockSignHere(false);
  };

  return (
    <>
      {showTextBox && (
        <>
          <div ref={signHereButtonRef} 
          onClick={handleAddTextBox} 
          className="sign-here-button">Sign Here</div>
        </>

      )}
    
      {textbox && (
        <div className="textbox-container">
          <input
            placeholder="First Name"
            type="text"
            value={textbox.firstName}
            onChange={(e) => handleChange('firstName', e.target.value)}
            className={errors.firstName ? "error" : ""}
            disabled={lockSignHere}
          />
          {errors.firstName && <span className="error-message">{errors.firstName}</span>}
          <input
            placeholder="Last Name"
            type="text"
            value={textbox.lastName}
            onChange={(e) => handleChange('lastName', e.target.value)}
            className={errors.lastName ? "error" : ""}
            disabled={lockSignHere}
          />
          {errors.lastName && <span className="error-message">{errors.lastName}</span>}
          <input
            type="text"
            placeholder="Email"
            value={textbox.email}
            onChange={(e) => handleChange('email', e.target.value)}
            className={errors.email ? "error" : ""}
            disabled={lockSignHere}
          />
          {errors.email && <span className="error-message">{errors.email}</span>}
          <button onClick={handleSaveTextBox} className="save-button">Add</button>
          <button onClick={handleDeleteTextBox} className="delete-button">Remove</button>
        </div>
      )}
      {savedTextboxes.map((savedTextbox) => (
        <div
          key={savedTextbox.id}
          onClick={(e) => handleTextBoxDrop(e, {}, savedTextbox)}
          className="saved-textbox"
        >
          <span>{savedTextbox.firstName}</span>
        </div>
      ))}
    </>
  );
};

export default TextBox;
