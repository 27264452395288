
const handleDoubleClickSignHere = (signId, e,isAddingTextBox,activeSignId,setTextboxes,setShowSquareBox,setActiveSignId,pdfViewerRef,scale,setSquareBoxCoordinates) => {

  if (isAddingTextBox) {
    console.log("Double Click!");
    if (signId === activeSignId) {
      setTextboxes((prevTextboxes) =>
        prevTextboxes.filter((textbox) => textbox.id !== activeSignId)
      );
      setShowSquareBox(false);
      setActiveSignId(null);
    } else {
      const rect = pdfViewerRef.current.getBoundingClientRect();
      const x = (e.clientX - rect.left) / scale;
      const y = (e.clientY - rect.top) / scale;
      setSquareBoxCoordinates({ x, y });
      setShowSquareBox(true);
      setActiveSignId(signId);
    }
  }
};
export default handleDoubleClickSignHere;