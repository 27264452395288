import React, { useState, useRef } from 'react';
import './css/DrawingCanvas.css';

const DrawingCanvas = ({ onSend }) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const startDrawing = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;
    const context = canvasRef.current.getContext('2d');

    context.beginPath();
    context.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  const draw = (e) => {
    if (!isDrawing) return;

    const { offsetX, offsetY } = e.nativeEvent;
    const context = canvasRef.current.getContext('2d');

    context.lineTo(offsetX, offsetY);
    context.stroke();
  };

  const endDrawing = () => {
    setIsDrawing(false);
  };

  const saveDrawing = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    if (context.getImageData(0, 0, canvas.width, canvas.height).data.every((pixel) => pixel === 0)) {
      alert('The canvas is empty. Draw something before saving.');
      return;
    }

    const newCanvas = document.createElement('canvas');
    newCanvas.width = canvas.width;
    newCanvas.height = canvas.height;
    const newContext = newCanvas.getContext('2d');

    newContext.clearRect(0, 0, newCanvas.width, newCanvas.height);
    newContext.drawImage(canvas, 0, 0);

    const image = newCanvas.toDataURL('image/png');

    const link = document.createElement('a');
    link.href = image;
    link.download = 'drawing.png';
    link.click();

    setIsSending(true);
    onSend({
      drawnImage: image,
    });
  };

  const clearCanvas = () => {
    const context = canvasRef.current.getContext('2d');
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  };

  return (
    <div className={`canvas-container animation ${isSending ? 'sending' : ''}`}>
      <canvas
        ref={canvasRef}
        width={300}
        height={300}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={endDrawing}
        onMouseOut={endDrawing}
      />
      <div className="buttons-container">
        <button className="button animation" onClick={saveDrawing}>
          Save
        </button>
        <button className="button animation" onClick={clearCanvas}>
          Clear
        </button>
        <button
          className="button animation"
          onClick={() => onSend({ pdfName: 'document_from_canvas.pdf', drawnImage: canvasRef.current.toDataURL('image/png') })}
          disabled={isSending}
        >
          {isSending ? 'Sending...' : 'Send'}
        </button>
      </div>
    </div>
  );
};

export default DrawingCanvas;