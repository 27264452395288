const createPayloadFromTextBoxes = (responseData, selectedOption,scale, pdfViewerRef, receivedDataMap) => {
    const payloadArray = Array.from(receivedDataMap.values()).map((textbox) => {
    const x = (textbox.x * scale + pdfViewerRef.current.scrollLeft) / scale;
    const y = (textbox.y * scale + pdfViewerRef.current.scrollTop) / scale;
    const id=Date.now;
    console.log(id);
    return {
      x: parseFloat(x),
      y: parseFloat(y),
      cName: `signature${id}`,
      pageNo: textbox.pageNo,
      firstName: textbox.firstName || '',
      lastName: textbox.lastName || '',
      email: textbox.email || '',
    };
  });

  const payload = {
    signTo: "PlusSign",
    documentId: responseData.documentId,
    coordinates: payloadArray,
  };

  console.log("payload ", payload, "\nresponse", responseData);
  return payload;
};

export default createPayloadFromTextBoxes;
