import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline, ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material';
import './css/global.css';
import Register from './login/Register';
import Error from './properties/Error';
import NotFound from './plusSign/views/not-found';
import SignDocsComponent from './FileComponent';
import reportWebVitals from './reportWebVitals';
import DarkHome from './pages/DarkMode';
import HandleFileChange from './properties/HandleFileChange';

const muiTheme = createTheme();

const App = () => {
  const [fileInfo, setFileInfo] = useState(extractParamsFromUrl());

  useEffect(() => {
    console.log(HandleFileChange.file);
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      setFileInfo(extractParamsFromUrl());
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);

  function extractParamsFromUrl() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const documentId = window.location.pathname.split('/').pop();

      if (!documentId) {
        throw new Error('documentId parameter is missing in the URL');
      }

      const email = urlParams.get('email');
      return { documentId, email };
    } catch (error) {
      console.log('Error extracting parameters from URL:', error.message);
      return { documentId: null, email: null };
    }
  }

  const { documentId, email } = fileInfo;

  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={!documentId && <DarkHome  />} />
            <Route path="/plusyoursoftech/ds/v1/*" element={documentId && email ? <SignDocsComponent documentId={documentId} email={email} /> : <Navigate to="/" />} />
            <Route path="/register" element={<Register />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));


reportWebVitals();
