import React from 'react';
import '../css/SentMail.css';

const SentMail = () => {
  const handleGmailLinkClick = () => {
    window.location.href = 'https://mail.google.com/';
  };

  return (
    <div className="sent-mail-container">
      <div className="message-box">
        <h1 className="message-title">Email sent successfully!</h1>
        <p className='message-text'>
          Thank you for choosing Plus Sign! Email notifications have been sent to all recipients regarding the signature. For any queries, please contact us at {' '}
          <a style={{color:'black'}} onClick={handleGmailLinkClick} href="mailto:mail@plusdocsign.com">mail@plusdocsign.com</a>
        </p>
      </div>
    </div>
  );
};

export default SentMail;
