const host_name = "https://plus-sign.onrender.com";
// const host_name = "http://localhost:9098";
const BASE_URL = `${host_name}/plusyoursoftech/ds/v1`
const Register_URL = `${host_name}/auth/register`;
const Log_In_URL = `${host_name}/auth/token`;
const validate_URL = `${host_name}/auth`;
const ONESPAN_URL="http://127.0.0.1:8000/api/send-to-sign/";
const Question_URL = `${host_name}/plusyoursoftech/v1/question`

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  BASE_URL,
  ONESPAN_URL,
  Log_In_URL,
  Register_URL,
  Question_URL,
  validate_URL
};
