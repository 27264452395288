import React from 'react';
import "../css/EmailRegistration.css";

const RegistrationSuccess = () => {
  // SuccessMessage.js

  return (
    <div className="container">
      <div className="success-message">
        <h1>Your Registration is Successful!</h1>
        <a href="https://mail.google.com/">Contact Us</a>
      </div>
    </div>
  );
}

export default RegistrationSuccess;
