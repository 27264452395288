import { useState, useEffect, useRef } from "react";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SignIn from "./Signin";
import axios from "axios";
import '../css/Register.css';
import Config from "../properties/Config";
import RegistrationSuccess from "./EmailRegistration";

const username_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const password_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Register = () => {
    const usernameRef = useRef();
    const errRef = useRef();

    const [username, setusername] = useState('');
    const [validName, setValidName] = useState(false);
    const [usernameFocus, setusernameFocus] = useState(false);

    const [password, setpassword] = useState('');
    const [validpassword, setValidpassword] = useState(false);
    const [passwordFocus, setpasswordFocus] = useState(false);

    const [matchpassword, setMatchpassword] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [email, setEmail] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [signInRedirect, setSignInRedirect] = useState(false);
    const [registrationSuccess, setRegistrationSuccess] = useState(false);

    useEffect(() => {
        usernameRef.current.focus();
    }, []);

    useEffect(() => {
        setValidName(username_REGEX.test(username));
    }, [username]);

    useEffect(() => {
        setValidpassword(password_REGEX.test(password));
        setValidMatch(password === matchpassword);
    }, [password, matchpassword]);

    useEffect(() => {
        setErrMsg('');
    }, [username, password, matchpassword]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isValidEntry = username_REGEX.test(username) && password_REGEX.test(password) && password === matchpassword;

        if (!isValidEntry) {
            setErrMsg("Invalid Entry");
            return;
        }

        try {
            const response = await axios.post(
                Config.Register_URL,
                JSON.stringify({ id: Math.floor(Math.random() * 1000), username, password, email }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            console.log(response?.data);
            console.log(response?.accessToken);
            console.log(JSON.stringify(response));
            setSuccess(true);
            setRegistrationSuccess(true);

            setusername('');
            setpassword('');
            setMatchpassword('');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Registration Failed');
            }
            errRef.current.focus();
        }
    };

    if (signInRedirect) {
        return <SignIn />;
    }

    if (registrationSuccess) {
        return <RegistrationSuccess />;
    }

    return (
        <>
            {success ? (
                <section>
                    <h1>Success!</h1>
                    <p style={{ fontSize: "22px" }}>
                        <a href="#">Sign In</a>
                    </p>
                </section>
            ) : (
                <section>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <h1 className="heading">Register</h1>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            id="username"
                            ref={usernameRef}
                            autoComplete="off"
                            onChange={(e) => setusername(e.target.value)}
                            value={username}
                            placeholder="Username"
                            required
                            aria-invalid={validName ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setusernameFocus(true)}
                            onBlur={() => setusernameFocus(false)}
                        />
                        <p id="uidnote" className={usernameFocus && username && !validName ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            4 to 24 characters.<br />
                            Must begin with a letter.<br />
                            Letters, numbers, underscores, hyphens allowed.
                        </p>
                        <input
                            type="email"
                            id="email"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                        />
                        <input
                            type="password"
                            id="password"
                            onChange={(e) => setpassword(e.target.value)}
                            value={password}
                            placeholder="Password"
                            required
                            aria-invalid={validpassword ? "false" : "true"}
                            aria-describedby="passwordnote"
                            onFocus={() => setpasswordFocus(true)}
                            onBlur={() => setpasswordFocus(false)}
                        />
                        <p id="passwordnote" className={passwordFocus && !validpassword ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            8 to 24 characters.<br />
                            Must include uppercase and lowercase letters, a number and a special character.<br />
                            Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                        </p>
                        <input
                            type="password"
                            id="confirm_password"
                            placeholder="Confirm Password"
                            onChange={(e) => setMatchpassword(e.target.value)}
                            value={matchpassword}
                            required
                            aria-invalid={validMatch ? "false" : "true"}
                            aria-describedby="confirmnote"
                            onFocus={() => setMatchFocus(true)}
                            onBlur={() => setMatchFocus(false)}
                        />
                        <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Must match the first password input field.
                        </p>
                        <button disabled={!validName || !validpassword || !validMatch}>Sign Up</button>
                    </form>
                    <p>
                        Already registered?<br />
                        <span className="line">
                            <a href="#" onClick={() => setSignInRedirect(true)}>Sign In</a>
                        </span>
                    </p>
                </section>
            )}
        </>
    )
}

export default Register;
