import React, { useEffect } from "react";
import Config from "./Config";

const HandleFileChange = async (event, data) => {
  const file = event.target.files[0];
  
  console.log("Selected file:", file);

  const formData = new FormData();
  formData.append("files", file);
  for (const key in data) {
    formData.append(key, data[key]);
  }

  try {
    const response = await fetch(Config.BASE_URL + "/document-upload", {
      method: "POST",
      body: formData,
      mode: "cors",
    });
    if (response.ok) {
      const responseData = await response.json();
      console.log("Upload successful:", responseData);
      console.log("fileName:", responseData);
      handleResponseData(responseData);

      return { file, responseData }; 
    } 
    else if(response.status == 400)
    {
      const responseData = await response.json();
       const error = responseData.errorMessage;
       return {error};
       
    }
    else {
      console.error("Upload failed:", response.statusText);
      return { file, error: response.statusText };
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    return { file, error: error.message }; 
  }
};

const handleResponseData = (responseData) => {
  fetch(Config.BASE_URL + "/chat-docs", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      fileName: responseData.fileName,
      folderName: responseData.folderName
    })
  })
  
  .then(response => {
    if (response.ok) {
      console.log("URL executed successfully");
    } else {
      console.error("URL execution failed:", response.statusText);
    }
  })
  .catch(error => {
    console.error("Error executing URL:", error);
  });
};

export default HandleFileChange;